// Grid Layouts
.grid {
  // default autogrid
  &[data-variant*='default'] {
    --gutter: 3rem;
    --min: 10rem;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));
    grid-auto-flow: dense;

  }
  // mainnav drawer menus & layouts (layout editor) & footer 
  // based on default Kirby Grid
  // you need to specify the columns on elements with syle="--columns: NUMBER"
  &[data-variant*='mainnav-drawer'],
  &[data-variant*='layouts'] {
    @include grid-default(); // reusable mixin with optional params '$columns, $gutter'
  }

  &[data-variant*='footer'] {
    @include grid-default(12, calc(var(--grid-gutter) * 1 )); 
    // override media query to start at small not at lg
    grid-template-columns: repeat(12, 1fr); 
    grid-column: span var(--columns);

    &[data-variant*='footer-main'] {
      .column {
        grid-column: span var(--columns);
      }
      .column-menu {
        --columns: 12; 
        @include media-query('sm') {
          --columns: 6;
        }
        @include media-query('lg') {
          --columns: 4;
        }
        @include media-query('xl') {
          --columns: 2;
        }
      }
      .column-spacer {
        display: none; 
        @include media-query('xxl') {
          display: block; 
          --columns: 1;
        }
      }
      .column-contact {
        --columns: 12; 
        @include media-query('sm') {
          --columns: 6;
        }
        @include media-query('xxl') {
          --columns: 5;
        }
      }
    }

    &[data-variant*='footer-colophon'] {
      .column {
        grid-column: span var(--columns);
      }
      .column-menu {
        --columns: 12; 
        order: 2;
        @include media-query('sm') {
          --columns: 4;
          order: 1;
        }
        @include media-query('md') {
          --columns: 3;
        }
      }
      .column-sponsors {
        --columns: 12;
        order: 1; 
        @include media-query('sm') {
          --columns: 8;
          order: 2;
        }
        @include media-query('md') {
          --columns: 9;
        }
      }
    }
  }

  // projects || pages || publications autogrid 
  // not that pages currently also covers the templates 'landing', 'support', 'schools' 
  &[data-variant*='projects'],
  &[data-variant='events'],
  &[data-variant='pages'],
  &[data-variant='publications'],
  &[data-variant='offers'] {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
    grid-gap: var(--grid-gutter);
    
    @include media-query('lg') {
      grid-gap: calc(var(--grid-gutter) * 3);
    }
  }

  // news grid (blog/events/tours)
  // content with sidebar 
  &[data-variant*='news'] {
    
    // @include grid-default(); 
    @include grid-default(12, calc(var(--grid-gutter) * 3 )); 
    // @include media-query('xxl') {}
  }

  // post grid within articles list (blog/events/tours)
  &[data-variant*='post'] {
    --columns: var(--columns);
    --gutter: var(--grid-gutter);
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;

    > .column {
      margin-bottom: var(--gutter);
    }

    @media (min-width: 640px) {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: calc(var(--gutter) * 2);
      > .column {
        grid-column: span var(--columns);
      }
    }
    @include media-query('lg') { }
  }

  // publication (single) || materials
  // To do -> Re-Factor and streamline (similar to news pages but might need a block editor)
  &[data-variant='publication'],
  &[data-variant='materials'],
  &[data-variant='offer-tour'],
  &[data-variant='page'],
  &[data-variant='legal']  {
    // see news template
    @include grid-default(12, calc(var(--grid-gutter) * 3 )); 
  }

  // SECTIONS
  &[data-variant*='introduction'] {
    @include grid-default(12, calc(var(--grid-gutter) * 3 )); 

    // order
    .column:nth-of-type(1) {
      order: 2;
    }
    .column:nth-of-type(2) {
      order: 1;
    }
    @include media-query('lg') { 
      .column {
        display: flex;
        align-items: center;
        margin-bottom: 0; 
      }
      .column:nth-of-type(1) {
        order: 1;
      }
      .column:nth-of-type(2) {
        order: 2;
      }
    }
  }
}





// For Reference ONLY Original Kirby Code
// Base 12 col css var grid (Kirby)
/*
.grid {
    --columns: 12;
    --gutter: 3rem;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
  }
  .grid > .column {
    margin-bottom: var(--gutter);
  }
  
  .autogrid {
    --gutter: 3rem;
    --min: 10rem;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));
    grid-auto-flow: dense;
  }

  @include media-query('lg') {
  
    .grid {
      grid-template-columns: repeat(12, 1fr);
    }
    .grid > .column {
      grid-column: span var(--columns);
    }
  }
  */
// END Grid (Kirby)