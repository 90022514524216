// SOME CONTENT OVERRIDES (content comes after INTRO) 
// mostly spacing adjustments

.content {
    // Single Articles (w sidebar)
    // we need some exceptions, if there is no intro (eg on single articles)
    // single news/or article / event / tour ...
    @at-root .container.grid {
        &[data-variant*='article'],
        &[data-variant*='event'],
        &[data-variant*='tour'] {
            margin-top: var(--margin-xl);
        }
    }

    // Single Publication Meta below content
    @at-root .container.grid {
        &[data-variant*='publication'] {
            .article {
                .module, .module-meta {
                    margin-top: var(--margin-xl);
                }
            }
        }
    }
}