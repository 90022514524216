// nav wrapper
.nav-breadcrumbs {
    @include font-size-smaller(); 

    border-top-left-radius: .825rem; 
    border-top-right-radius: .825rem;
    
    // @extend .main; // extends .main, needs an inner element
    
    background-color: var(--color-black);
    // color: var(--color-white);
    color: rgba(255,255,255,0.6);

    @include media-query('sm-max') {
        border-top-left-radius: 0; 
        border-top-right-radius: 0;
    }
}

.nav-breadcrumbs > .inner {}

.nav-breadcrumbs .container { 
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-query('sm-max') {
        justify-content: flex-start;
    }
}

.nav-breadcrumbs-icon {
    height: 20px; // 2rem
    margin-right: 1rem;
    .icon {
        height: 100%; 
        width: auto;
    }
}

// breadcumbs <ol> 
.breadcrumbs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0.25rem 0; 
    text-align: right;

    @include media-query('sm-max') {
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
    }
}

.breadcrumbs > li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-right: 0.25rem; 
    }
    &:last-child {
        .icon {
            display: none; 
        }
    }
    &.is-active {
        color: rgba(255,255,255,0.9);
    }
    .icon {
        /*
        display: inline-block;
        position: relative;
        top: 5px; 
        */
        height: 22px;
        width: 22px; 
        padding: 0.1rem 0.25rem; 
    }
}

.breadcrumbs > li > a {}