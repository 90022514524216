.social {
    display: flex;
    padding: 0 .5rem;
  }
  .social a {
    padding: .5rem .5rem;
    color: var(--color-white);
    display: flex;
    align-items: center;
  }
  
  .social a svg,
  .social a svg g,
  .social a svg path { 
      fill: currentColor; 
      color: inherit; 
  }


.social-share {
  display: flex;
  li {
    &:first-child {
      margin-left: -1rem;
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem; 
    margin-right: 0.25rem; 
  }
  svg {
    height: 32px;
    width: auto;
  }
}

 // social links (networks) overides
 // This refers to all social-links-* (eg "-gwt" AND also "-jgwt")
 // Override this snippet in other context (eg footer.scss topbar.scss etc.)
.social[data-variant*='social-links-'] {
  padding: 0; 
}