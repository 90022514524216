// Import MetisMenu (JS) // https://onokumus.com/metismenujs/mm-vertical.html
@import '../../node_modules/metismenujs/scss/metismenujs';

// global
.menu-mobile {
    color: var(--color-white); 
    padding-bottom: 1.5rem;
}
.menu-mobile a {
    display: block;
    padding: 0.45rem 0; 
    transition: color 0.2s ease 0s; 

    &:hover, &[aria-current] {
        color: var(--color-orange); 
    }


}

// sub 
.menu-mobile ul.mm-collapse {
    padding-bottom: 1rem; 
}
.menu-mobile ul.mm-collapse a {
    @include font-size-smaller();
    padding: 0.5rem 0 0.5rem 1rem; 

    color: rgba(255,255,255,0.6);
    
    transition: color 0.2s ease 0s; 

    &:hover, &[aria-current] {
        color: rgba(255,255,255,0.92);
    }
}

// divider && Module header (Language, Follow us)
.menu-mobile-div,
.off-canvas-inner .module-header {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(255,255,255,0.2); 
    padding: 0.35rem 0;
    margin-bottom: 0.5rem; 
    color: var(--color-white);
}
.menu-mobile li + li.menu-mobile-div {
    margin-top: 1.5rem;
}

.off-canvas-inner .module-header {
    .h3 {
        @include font-size-regular();
    }
}
/*
.menu-mobile-link {
    // a direct link in tree without dropdown
}
.menu-mobile-div-head {

}
*/
// end divider


// social
.module-social {
    .social[data-variant*='social-links-'] {
        a {
        padding: 0;
        margin-right: 1rem; 
        transition: color .2s ease 0s;
        &:hover {
            color: var(--color-orange);
        }
        }
        svg {
        height: 24px;
        width: 24px;
        }
    }
}
