// absolute
.obj-fit-abs {
	position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
    	position: relative;
	    width: 101%;
	    height: 101% !important;
	    max-width: none;
	    top: 50%;
	    left: 50%;
	    -webkit-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	    object-fit: cover;
    }

    svg {
    	display: block;
    	position: absolute;
    	top: 0;
	    left: 0;
	    width: 100%;
	    object-fit: cover;
    } 
}

// relative
.obj-fit-rel {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
        position: relative;
        width: 101%;
        height: 101% !important;
        max-width: none;
        // top: 50%;
        // left: 50%;
        // -webkit-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
        object-fit: cover;
    }
}