/*
 * Load Plugin Styles 
*/ 
@import '../../node_modules/swiper/swiper.scss';
// modules
@import '../../node_modules/swiper/modules/navigation/navigation.scss';
@import '../../node_modules/swiper/modules/scrollbar/scrollbar.scss';


/*
 * Plugin Styles Overrides
*/ 
.swiper-slide {
    height: auto; // reset height for equal height slides
}

.swiper-wrapper {
    // padding-bottom: 5rem; 
}

/*
 * Custom gwt-swiper styles
*/ 
// scrollbar
$gwt-swiper-scrollbar-height: 40px; 
.gwt-swiper-scrollbar-container {
    overflow: hidden; 
    height: $gwt-swiper-scrollbar-height * 2; 
    @include media-query('lg') {
        height: $gwt-swiper-scrollbar-height * 3; 
    }   
}
.gwt-swiper-scrollbar {
    background: rgba(0, 0, 0, .3); 
    height: 2px;  
    top: $gwt-swiper-scrollbar-height; 
    border-radius: 3px;
    @include media-query('lg') {
        top: $gwt-swiper-scrollbar-height * 1.5; 
    }
}
/* .gwt-swiper-scrollbar .swiper-scrollbar-drag, */
.gwt-swiper-scrollbar-drag {
    position: relative; 
    height: $gwt-swiper-scrollbar-height; 
    cursor: grab;
    // top: -3px;
    top: ($gwt-swiper-scrollbar-height / 2) * -1;  
    &:hover .gwt-swiper-scrollbar-dragline {
        background-color: var(--color-orange); 
    }
}
.gwt-swiper-scrollbar-dragline {
    position: relative; 
    height: 8px;
    top: calc((#{$gwt-swiper-scrollbar-height} / 2) - 3px);  // offset height - scrolltrack height / 2 => (8px - 2px) / 2 = 3 
    background-color: var(--color-black); 
    border-radius: 4px;
    // width doesn't work (js dragsize or autp needed for correct calculation of scroll track
    // width: 25% !important; // overrides js dragsize on element
    transition: background-color 0.18s ease-in-out 0s;
    
}

// swiper container overflow (>992 || > 1400)
.swiper-overflow-container {
    overflow-x: hidden;
    padding-top: var(--padding-base); 
    padding-bottom:var(--padding-base); 
}
.swiper-overflow-container .container {
    overflow: visible;
}
.swiper-overflow-container .swiper {
    overflow: visible;
}


/*
.container-ext .swiper {
    @include media-query('lg') {
        overflow: visible !important; 
        
    }
}
*/

// swiper-overflow-container gradient (>992)
.swiper-overflow-container  {
    position: relative;

    &::before, &::after {
        display: none;
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%; 
        width: 5%; 
        opacity: 1;
        background: black;
        z-index: 2;
        pointer-events: none;
        transition: opacity 0.55s ease-in-out;

        @include media-query('lg') {
            display: block;  
        }
        @include media-query('xxl') {
            width: 10%;
        }
        @include media-query('hd') {
            width: 15%;
        }
    }
    &::before {
        left: 0;
        @include gradient-linear-left-white();

    }
    &::after {
        right: 0;
        @include gradient-linear-right-white();
    }
    /*
    &:hover {
        &::before, &::after {
            opacity: 1;
        }
    }
    */
}

// prev next buttons
.swiper-button-prev, .swiper-button-next {
    display: none; 
    height: 64px;
    width: 64px; 
    color: var(--color-text); 
    background: white;
    border-radius: 50%;
    transition: color 0.18s ease-in-out 0s;

    @include media-query('lg') {
        display: flex;
        top: 40%;   
    }
    @include media-query('xl') {
        top: 50%;   
    }

    &:hover, &:focus {
        color: var(--color-orange); 
    }

    // custom svg
    .icon {
        width: 42px;
        height: 42px; 
    }
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 32px; 
}
.swiper-button-next {
    .icon {
        padding-left: 4px; 
    }
}
.swiper-button-prev {
    left: 32px;
    .icon {
        padding-right: 4px; 
    }
}