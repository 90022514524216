.module {
    // similar to flow
    + * {
        margin-top: var(--flow-space, 3rem);
    }
}

.module-title {
    margin-bottom: var(--margin-m);

    // exceptions & overrides
    &.social-share-title {
        margin-bottom: 0;
    }
}

.module-meta {
    background-color: var(--color-light);
    border-radius: 0.5rem;
    padding: var(--padding-base);
}