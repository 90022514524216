// TEMPORARY WIP
.sidebar {
    @include font-size-smaller(); 
    // height: 100vh;
    height: 100%; 
    position: relative;
}
.sidebar-inner {
    background-color: var(--color-light);
    padding: var(--padding-base); 
    border-radius: 0.5rem;
    
    // position: relative; 
    // overflow: auto;
    // display: table;
    @include media-query('lg') {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}