html {
    text-size-adjust: 100%; // experimental
}

body {
    font-family: var(--font-family-sans); 
    // Fluid Type
    // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
    // https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft?from-embed
    // font-size: clamp(1.09rem,1rem + .47vw,1.33rem); // piccalilli demo
    // correct calculations (see sandbox above)
    // BASE: min vp 360 / max vp 1888 / m fontsize 1rem / max fontsize 1.25 (1 rem = 16px) results in :
    // font-size: clamp(1rem, 0.9411rem + 0.2618vw, 1.25rem); // min prefered max
    @include font-size-regular(); // font-size: clamp(0.9rem, 0.9411rem + 0.2618vw, 1rem);
    
    line-height: 1.5;
    color: var(--color-text);
    background: var(--color-blue);
    overflow-x: hidden;
}
