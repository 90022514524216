// HEADER INTRO
header.intro {
    // 01 FULL WIDTH (DEFAUL)
    &[data-variant*='full-width'] {
        padding: 0; // reset var(--padding-base) to full width
        .container {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            // margin: 0 0 0 0; 
            margin-bottom: var(--margin-xl); 
        }
        .intro-image {
            width: 100%;
            height: 100%;
            position: absolute; // start defining size by intro-caption
            overflow: hidden;
            top: 0;
            left: 0;
            bottom: 0;
        }
        .intro-image picture {
            // reset
            display: initial;
        }
        .intro-image img { 
            display: inline-block;
            height: 100%;
            min-width: 100%;
            object-fit: cover; 
        }
        .intro-caption {
            display: flex;
            position: static;
            flex: auto;
            width: 100%; 
            min-height: 600px;
            @include media-query('md') {
                min-height: 440px;
            }
            @include media-query('xl') {
                min-height: 640px;
            }
        }
        // NOTE you might want to work with > div > div and move theses setting one nested level deeper for controling background colors (readability of text)
        .intro-caption  > div {
            @extend .container;
            display: flex;
            flex: auto;
            flex-flow: column;
            align-items: center;
            justify-content: flex-end;
            padding: 25px var(--padding-base);
            position: relative;
            @include media-query('sm-max') {
                max-width: 300px !important;
            }
            @include media-query('md') {
                padding: 35px var(--padding-base);
                align-self: flex-end; // vertival
                align-items: flex-start; // horizontal
            }
            /*
            @include media-query('xl') {
                // paddings
            }
            */
        }

        .intro-caption-body {
            background-color: rgba(255,255,255,.95);
            box-shadow: 0 3px 15px rgb(0 0 0 / 5%);
            box-sizing: border-box;
            max-width: 60ch;
            padding: var(--padding-base); 
            border-radius: 0.5rem; 
        }
    }

    // 02 CONTENT WIDTH
    &[data-variant*='ctn-width'] {
        // padding: 0; // reset var(--padding-base) to full width
        @include media-query('lg') {
            padding-top: var(--margin-xl);
            padding-bottom: var(--margin-xl);
        }
        .container {
            @include grid-default(); 
        }
        .intro-image {
            position: relative;
            overflow: hidden;
            margin-left: calc(var(--padding-base) * -1); // bleed
            margin-right: calc(var(--padding-base) * -1); // bleed
            @include media-query('md') {
                height: 380px;   
            }
            @include media-query('lg') {
                order: 2;
                margin-left: var(--grid-gutter); // bleed reset + offset gutter
                margin-right: 0; // bleed reset
                @include borders-m; // mixin
            }
            @include media-query('xl') {
                // width: calc( (100% / 3 * 2) - var(--grid-gutter)); 
                height: auto; 
            }
        }
        /*
        .intro-image picture {
            // reset
            // display: initial;
        }
        */
        .intro-image img { 
            // display: inline-block;
            height: 100%;
            min-width: 100%;
            object-fit: cover; 
        }
        .intro-caption {
            // padding: var(--padding-base); // set on mobile
            // margin-bottom: var(--margin-xl) !important; 
            position: relative; 
            
            @include media-query('lg') {
                margin-bottom: 0 !important; 
                order: 1;
                // padding-left: 0; // reset on large sind regular header padding is active again
                // padding-right: 0;  // reset on large sind regular header padding is active again
            }
        }
        // .intro-caption > .intro-caption-inner > .intro-caption-body
        .intro-caption-body {
            @include media-query('lg') {
                padding-right: var(--grid-gutter); 
            }
        }
    }


    // 03 TITLE ONLY CONTENT WIDTH
    &[data-variant*='title-only'] {
        // set margin bottom if working with bg color or if you please
        padding-top: var(--margin-l);
        margin-bottom: var(--margin-l);

        @include media-query('md') {

        }
        @include media-query('lg') {
            padding-top: var(--margin-xl);
            padding-bottom: var(--margin-xl);
            // margin-bottom: var(--margin-xl);
        }
    }

    // ALL VARIANTS
    // STYLES
    h1 {
        // headline (subline
        margin-bottom: var(--margin-m);
        span {
            display: block;
        }
    }
}


// VARIANTE A WORKING (INITIAL)
/*
&[data-variant*='full-width'] {
        padding: 0; // reset var(--padding-base) to full width
        .container {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            // margin: 0 0 0 0; 
        }
        .intro-image {
            width: 100%;
            height: 300px;
            @include media-query('md') {
                height: 100%;
                position: absolute; // start defining size by intro-caption
                top: 0;
                left: 0;
                bottom: 0;
            }
        }
        .intro-image picture {
            // reset
            display: initial;
        }
        .intro-image img { 
            display: inline-block;
            height: 100%;
            min-width: 100%;
            object-fit: cover; 
        }
        .intro-caption {
            display: flex;
            position: static;
            flex: auto;
            width: 100%; 
            @include media-query('md') {
                min-height: 440px;
            }
            @include media-query('xl') {
                min-height: 640px;
            }
        }
        // NOTE you might want to work with > div > div and move theses setting one nested level deeper for controling background colors (readability of text)
        .intro-caption  > div {
            @extend .container;
            display: flex;
            flex: auto;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            padding: 25px var(--padding-base);
            position: relative;
            @include media-query('sm-max') {
                max-width: 300px !important;
            }
            @include media-query('md') {
                padding: 35px var(--padding-base);
                align-self: flex-end; // vertival
                align-items: flex-start; // horizontal
            }
            @include media-query('xl') {
                // paddings
            }
        }
    }
    */