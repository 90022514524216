h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, {
    font-family: var(--font-family-slab);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    line-height: 1.15;
}

h1, .h1 {
    // https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft?from-embed
    // min 360 max 1888 minFont 1.5 MaxFont 3 (base 1rem = 16px)
    font-size: clamp(1.5rem, 1.1466rem + 1.5707vw, 3rem);
    font-weight: 700;
}

h2, .h2 {
    // min 360 max 1888 minFont 1.3 MaxFont 2 (base 1rem = 16px)
    font-size: clamp(1.3rem, 1.1351rem + 0.7330vw, 2rem);
    font-weight: 700;
}

h3, .h3 {
    // min 360 max 1888 minFont 1.15 MaxFont 1.65 (base 1rem = 16px)
    font-size: clamp(1.15rem, 1.0322rem + 0.5236vw, 1.65rem);
    font-weight: 700;
}

h4, .h4 {
   @include font-size-regular();
   font-weight: 700;
}