/*
.text {
    // line-height: 1.5em;
  }

.text h1,
.h1
  // .intro 
  {
    // font-size: 2rem;
    // margin-bottom: 3rem;
    // line-height: 1.25em;
  }

.text h2,
.h2 {
  // font-size: 1.25rem;
  // font-weight: 600;
  // margin-bottom: 1.25rem;
}
.text h3,
.h3 {
  // font-weight: 600;
}
*/

  .text a,
  .block-type-text a,
  .block-content .block a,
  .meta-list a {
    text-decoration: underline;
    transition: color 150ms;
    &:hover {
      color: var(--color-orange);
    }
  }
  .text :first-child {
    margin-top: 0;
  }
  .text :last-child {
    margin-bottom: 0;
  }
  .text p,
  .text ul,
  .text ol {
    margin-bottom: 1.5rem;
  }
  .text ul,
  .text ol {
    margin-left: 1rem;
  }
  .text ul p,
  .text ol p {
    margin-bottom: 0;
  }
  .text ul > li {
    list-style: disc;
  }
  .text ol > li {
    list-style: decimal;
  }
  .text ul ol,
  .text ul ul,
  .text ol ul,
  .text ol ol {
    margin-bottom: 0;
  }
  
.text .codeblock {
  display: grid;
}
.text code {
  font-family: var(--font-family-mono);
  font-size: 1em;
  background: var(--color-light);
  padding: 0 .5rem;
  display: inline-block;
  color: var(--color-black);
}
.text pre {
  margin: 3rem 0;
  background: var(--color-black);
  color: var(--color-white);
  padding: 1.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  line-height: 1.5rem;
}
.text pre code {
  padding: 0;
  background: none;
  color: inherit;
}
.text hr {
  margin: 6rem 0;
}
.text dt {
  font-weight: 600;
}
.text blockquote {
  font-size: 1.25rem;
  line-height: 1.325em;
  border-left: 2px solid var(--color-black);
  padding-left: 1rem;
  margin: 3rem 0;
  max-width: 25rem;
}
.text blockquote footer {
  font-size: .875rem;
  font-style: italic;
}
.text figure {
  margin: 3rem 0;
}
.text figcaption {
  padding-top: .75rem;
  color: var(--color-text-grey);
}


// block-editor
.block-content {
  --flow-spac: 6rem !important;
}

// block types
.block-type-heading {
  h2 {
    margin-top: 5rem;
  } 
  h3 {
    margin-top: 3rem; 
  }
}

.block-type-list {
  ul {
    
    list-style-position: inside;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  ul > li {
    list-style-type: disc;
  }
}