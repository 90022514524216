/*
 * card
 */ 
.card-wrapper {
    
    @include card-wrapper();
    max-width: 420px; 
    // background-color: var(--bg);
    
    
    &[data-variant*='link'] {
        @include card-wrapper-hover(); 
    }

    &[data-variant*='card-project'],
    &[data-variant*='card-event'],
    &[data-variant*='card-post'] {
        background-color: var(--color-white);
    }

    &[data-variant*='card-info'] {
        color: var(--color-white);
        background-color: var(--color-blue);
    }

    &[data-variant*='article-no-cover'] {
        max-width: 100%;
        width: 100%; 
    }
    &[data-variant*='card-mini'] {
        height: auto;
        max-width: 100%;
        width: 100%;
    }
}

.card {
    @include font-size-regular-layout(); 

    // card mini (rows)
    &[data-variant*='card-row'] {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
    }
    &[data-variant*='has-cover'] {
        .card__image {
            width: 180px; 
            margin-bottom: 0; 
            margin-right: 1rem; 
        }
        .card__body {
            max-width: calc(100% - 180px); 
        }
    }
}

.card__image {
    @include borders-s; // mixin
   
    position: relative;
    overflow: hidden;
    margin-bottom: calc(var(--grid-gutter) * 1.5); // 1.5rem
}

.card__body {
    > p {
        // font-size: 16px;
    } 
    &[data-variant='card-body-flex'] { 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.card-title {
    // TEMPORARY
    // font-size: 21px;
    // line-height: 1.25;
    margin-top: calc(var(--flow-space,1em) / 8.125); 
}



/*
 * card-image
 * an image, that behaves like a card wrapper on hover 
 * eg on entry posts (event/tour/article)
 */ 
.card-image-wrapper {
    @include card-wrapper();
    @include card-wrapper-hover(); 
    /*
    a { }
    */
 }
.card-image {
    @include borders-s; // mixin
    position: relative;
    overflow: hidden;
}