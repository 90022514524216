.hamburger {
    display: flex;
    padding-right: 0; // override
    @include media-query('lg') {
        display: none; 
    }
}

.hamburger-label {
    font-family: var(--font-family-slab);
    color: var(--color-white); 
    padding: 0 0.5rem; 
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}