// font size regulare 
// (smaller than html/body on layout elements) eg on mainnav and footer
@mixin font-size-regular() {
  // font-size: clamp(0.9rem, 0.9411rem + 0.2618vw, 1rem);
  // BASE: min vp 360 / max vp 1888 / m fontsize 1rem / max fontsize 1.25 (1 rem = 16px) results in :
  font-size: clamp(1rem, 0.9411rem + 0.2618vw, 1.25rem); // min prefered max
}
@mixin font-size-regular-layout() {
  // a variant of regular (smaller - scales to max 1rem for layouts)
  // old regular values for layout elements (navbar / footer etc)
  // font-size: clamp(0.9rem, 0.9411rem + 0.2618vw, 1rem);
  font-size: clamp(0.9rem, 0.8764rem + 0.1047vw, 1rem);
}

@mixin font-size-smaller() {
  // experimental - REFINE THIS for all small text in main area (meta data / sidebar / bordered elements)
  font-size: clamp(0.825rem, 0.8611rem + 0.2618vw, 0.9rem);
}

// Font Formats
@mixin meta-type() {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 500;
}

// Caption 
@mixin caption() {
  font-size: clamp(0.825rem, 0.9411rem + 0.2618vw, 1rem); // min prefered max
  font-weight: var(--weight-semi-bold); 
}

// divider
@mixin divider-line($color) {
    content: "";
    display: block;
    width: 1.5rem;
    height: 2px;
    background: $color;
    margin-bottom: 1.5rem;
}

// borders with radius(es)
@mixin borders-m($color: var(--color-black)) {
    // border: .07vw solid;
    // border-radius: .825vw;
    // padding: 1vw 1.4vw;
    border: 0.0725rem solid $color;
    border-radius: .825rem;
}
@mixin borders-s($color: var(--color-black)) {
    border: 0.0725rem solid var(--color-black);
    border-radius: .5rem; // var(--radius-s)
}

// cards 
// (must come after borders above)
@mixin card-wrapper() {
  display: flex;
  position: relative; 

  @include borders-m; // mixin

  padding: var(--padding-base) calc(var(--padding-base) * 1.25);
  overflow: hidden;
  height: 100%;
}

@mixin card-wrapper-hover() {
  transition: transform .2s ease-in,box-shadow .2s ease-in;
  cursor: pointer;
  &:hover {
    // transform: translate(.347vw,-.347vw);
    // box-shadow: -0.347vw 0.347vw 0 0 var(--color-orange);
    transform: translate(0.75rem,-0.75rem);
    box-shadow: -0.75rem 0.75rem 0 0 var(--color-orange);
  }
}


// grid default (based on kirby)
@mixin grid-default($columns: 12, $gutter: var(--grid-gutter)) {
    // --columns: $columns;
    // --gutter: $gutter;
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: 1fr;

    > .column {
      margin-bottom: $gutter;
    }

    @include media-query('lg') {
  
      grid-template-columns: repeat(12, 1fr);
      > .column {
        grid-column: span var(--columns);
      }
    }
}