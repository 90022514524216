.message {
    
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    max-width: 44rem;

    @at-root #{&}-info { 
        background: rgba(35,40,204,0.08); // var(--color-blue);

        .message-icon {
            background-color: var(--color-blue);
            
        }

        .message-text {
            h3 {
                color: var(--color-blue);
            }
        }

    }
}

.message-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 64px;
    width: 64px; 

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 42px;
        width: 42px; 
        color: var(--color-white);
    }
}
.message-text {
    margin-right: var(--margin-l);
    padding: calc(var(--padding-base) * 2);
    
    h3 {
        margin-bottom: 1.5rem; 
    }
}