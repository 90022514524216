// responsive helpers
.mobile-only {
    @include media-query('lg') {
        display: none !important; 
    }
}

.desktop-only {
    display: none; 
    @include media-query('lg') {
        display: block; 
    }
}


// visibility helpers
.d-none {
    display: none; 
}

.v-none {
    visibility: hidden;
}

.sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

// eg used in section headers (hidden headlines - also us aria-hidden="true" on to hide better from screen readers element!
.sr-d-hidden {
    // position:absolute;
    // left:-10000px;
    // top:auto;
    position: relative;
    top: auto; 
    left: -10000px; 
    width: 0px;
    height: 1px;
    overflow:hidden;
}
