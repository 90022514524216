/* 
 * Layouts created by Layout editor (see layout_editor.php)
 */ 

section[data-variant*='layouts'] {
    .grid {
        @include grid-default(); 
    }

    .column-main {
        // first column
        &[data-variant*='is-first'] {
            @include media-query('lg') {
                padding-right: calc(var(--padding-base) * 3 );
            }
            @include media-query('xxl') {
                padding-right: calc(var(--padding-base) * 6 );
            }
        }
        // last column (second column)
        &[data-variant*='is-last'] {
            @include media-query('lg') {
                padding-left: calc(var(--padding-base) * 3 );
            }
            @include media-query('xxl') {
                padding-left: calc(var(--padding-base) * 6 );
            }
        }
        // single colums (width 1/1 => 12 columns)
        // make it 8 columns wide and adopt paddings to left / right
        &[data-variant*='is-single'] {
            padding-left: 0; // reset
            padding-right: 0; // reset
            grid-column: span 8;

            @include media-query('lg') {
                // grid-column: span 8;
                grid-column-start: 3; // offset by 2 to center it
                grid-column-end: 11;
                padding-left: calc(var(--padding-base) * 1.5 );
                padding-right: calc(var(--padding-base) * 1.5 );
            }
            @include media-query('xxl') {
                padding-left: calc(var(--padding-base) * 3 );
                padding-right: calc(var(--padding-base) * 3 );
            }
        }
    }
    .column-aside {

    }
}