[data-variant*='news'] {
    .article .article-header {
        margin-bottom: var(--margin-m);
        @include media-query('lg') {
            margin-bottom: var(--margin-l);
        }
    }

    .articles-list {
        .entry-article {
            margin-bottom: var(--margin-l);
        }
    }
}