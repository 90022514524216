// categories, dates etc.
.meta {
    // @include font-size-regular();
    @include font-size-smaller(); 
    // font-size: 0.825rem;
    @at-root .meta__cat {
        @include meta-type();

        .meta-divider {
            text-transform: lowercase;
            font-style: italic;
        }
    }
}