.tags {
}

.tag {
    display: inline-block;
    padding: 0.3rem 0.4rem 0.2rem;
    margin-bottom: 0.4rem; 
    border-radius: 0.5rem;
    border: 1px solid var(--color-orange);
    //to do
    background: #f3eee4;
    color: #1c2334;
    font-weight: 700;
    font-size: clamp(.88rem,.83rem + .24vw,1rem);

    line-height: 1;
    text-decoration: none;
    text-transform: capitalize;

    &:focus, &:hover {
        background: var(--color-orange);
        color: var(--color-white);
    }

    &:active, &:focus, &:hover {
        transition: background 150ms,color 150ms;
    }
}