.video-container {
    position: relative;
    /* falls back to 16/9, but otherwise uses ratio from HTML */
    padding-bottom: calc(var(--aspect-ratio, .5625) * 100%); 
}

.video-container {
    iframe, embed, object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}