/*
    html dimm / overlay element 
    triggered by js html class 
    eg "mainmenu-desktop-open"
*/
$gwt-dimm-opacity: 0.82; 
$gwt-dimm-speed: 0.75s;
.gwt-dimm {
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; 
    background-color: var(--color-black);
    pointer-events: none;
    opacity: 0; 
    z-index: 4;
}
// js triggered state
.mainmenu-desktop-open {
    // handle dimming
    .gwt-dimm {
        pointer-events: all;
        opacity: $gwt-dimm-opacity; 

        -webkit-animation: fadein $gwt-dimm-speed; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein $gwt-dimm-speed; /* Firefox < 16 */
         -ms-animation: fadein $gwt-dimm-speed; /* Internet Explorer */
          -o-animation: fadein $gwt-dimm-speed; /* Opera < 12.1 */
             animation: fadein $gwt-dimm-speed;
    }
    // disable scroll (experimental)
    body {
        overflow-y: hidden;
    }
}

// Animation Fade In
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: $gwt-dimm-opacity; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: $gwt-dimm-opacity; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: $gwt-dimm-opacity; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: $gwt-dimm-opacity; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: $gwt-dimm-opacity; }
}