// quicknav menu
.module-quicknav {
    .module-header {
        margin-bottom: var(--margin-s);
        p {
            font-weight: 700; 
            @include font-size-regular-layout();
        }
    }
    
}
.quicknav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.quicknav-item {
    &:not(:last-child) {
        border-bottom: 1px solid var(--color-black);
    }
}

.quicknav-label {
    span {
        @include font-size-smaller(); 
    }
}

.quicknav-item a {
    padding: 0.5rem 0; 

    // override links component if needed
    &.link {}
    &.link-icon {}
}
