/*
.module-sponsors {

}
*/

.sponsors {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // justify-content: flex-start;
    // align-items: center;
    @include media-query('sm') {
        flex-direction: row;   
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    > .sponsors-icon {
        position: relative;
        // width: 75%;
        height: 85px;
        // margin-right: auto;
        // margin-left: auto;
        padding: 10px; 
        margin-bottom: 1rem; 
        @include media-query('sm') {
            margin-right: 5%;
            margin-left: 0;
            // width: 45%;
        }
        @include media-query('lg') {
            // width: 30%;
        }
         /*
        @include media-query('xl') {
            width: 25%;
        }
        */
        a {
            display: block;
            // width: 100%;
            height: 100%; 
            transition: transform 0.18s ease; 
            &:hover {
                transform: translateY(-3px);
                svg path {
                    fill: var(--color-orange);
                }
            }
        }

        svg {
            // width: 100% !important;
            // height: auto !important; 
            height: 100%; 
        }
    }
}