
.footer {
    @include font-size-regular-layout();

    // padding: 9rem calc(2 * var(--padding-base)) 6rem;
    padding: 6rem calc(2 * var(--padding-base)) 6rem;
    line-height: 1.5em;
    background: var(--color-blue);
    color: var(--color-white);
    
    @include media-query('sm-max') {
        padding: 9rem var(--padding-base) 6rem;
    }

}
.footer > .container:not(:last-child) {
  margin-bottom: var(--margin-l);
}
.footer > .container:before {
  @include divider-line(var(--color-white));
}

// Formats 
.ft-column-title {
  @include font-size-regular-layout();
  font-family: var(--font-family-sans);
  // font-size: 0.875rem;
    font-weight: var(--weight-bold);
    // color: var(--color-orange); 
    // letter-spacing: 0.125rem;
    // text-transform: uppercase;
    padding: 0.2em 0; 
}

.ft-menu {
  @include font-size-smaller();

}
.ft-menu-item a {
  display: block; 
  padding: 0.2em 0; 
  transition: transform 0.18s ease; 
  &:hover {
    transform: translateX(3px);
    color: var(--color-orange); 
  }
}

// specif columns 
// contact (card)
.column-contact {
  .card-wrapper {
    border-color: var(--color-white);
  }
  .card .text {
    @include font-size-smaller();
  }
  .card .text:not(:last-child) {
    margin-bottom: var(--margin-m); 
  }

  .card a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  // social 
  .card .text[data-variant='social']  {
    p {
      // text-decoration: underline;
      border-bottom: 1px solid var(--color-white);
      margin-bottom: .825rem;
      padding-bottom: .5rem;
    }
  }
  .social[data-variant*='social-links-'] {
    a {
      padding: 0;
      margin-right: 1rem; 
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }
}
