// eg in mainnav mobile (off canvas nav)
.module-languages {
    &[data-variant*='inverted'] {
        color: var(--color-white); 
    }

    .module-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.module-languages .module-icon {
    height: 20px; // 2rem
    margin-right: 1rem;
    .icon {
        height: 100%; 
        width: auto;
    }
}

// ul languages
.module-language {
    display: inherit; 
    justify-content: inherit; 
    align-items: inherit; 
}
.module-language > li {
    margin-right: 0.5rem;
    a {
        padding: 0.5rem;
    } 
    &.active {
        color: var(--color-orange);
    }
}