/*
 * drawer 
 * <ul li> mainmenu menu-item
 * Until drawer is not used elsewhere but within the mainnav, we treat the drawer as root component in css
 */
.drawer {
    // state open (js)
    &.is-open {
        .drawer-canvas {
            display: block; 
        }
    }
    // state closed (js) 
    // -> siblings to "is-open"
    // class only exists, if one drawer is-open, then the others are "is-closed"
    &.is-closed {
        // dimm drawer toggles
        .drawer-toggle {
            opacity: 0.72; 
            &:hover {
                color: var(--color-white);
                opacity: 1; 
            }
        }
    }

     // Override menu a (padding offset)
    &:last-child .drawer-toggle {
        padding-right: 0px; 
    } 
}

// li > a (also menu a)
.drawer-toggle {
    position: relative;
    z-index: z(header); // 4 -> see functions

    transition: all 150ms;

    // font-weight: var(--weight-semi-bold); // bold
    letter-spacing: 0.02rem;
    // active link state
    // @at-root #{&}.is-active {}
    &:after {
        content: ' ';
        display: none;
        width: 100%;
        height: 3px; 
        border-radius: 0.05rem;
        background-color: var(--color-white);
    }
    &[aria-current] {
        &:after {
            display: block;
            background-color: var(--color-orange);
        }
    }
    &:hover {
        color: var(--color-orange);
    }
    /*
    &:active, &:focus, &:hover {
        
    }
    */
}

// li > div (drawer canvas)
.drawer-canvas {
    display: none; 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // full bleed (extend browser width)
    width: 100vw;
    margin-left: calc(50% - 50vw);
    // spacing, color, z-index (below menu items, logo etc)
    min-height: 320px;
    background-color: var(--color-blue);
    z-index: z(drawer); // 3 -> see functions

    padding: 120px calc((2 * var(--padding-base)) + (var(--padding-base) *0.5) ) 40px;

    // mini cards in menu
    .card-wrapper[data-variant*='card-mini'] {
        margin-top: 1.5rem;
    }
    
}


 // drawer menu
 /*
 .drawer-menu {}
 .drawer-menu-item {}
 */

 .drawer-menu-item a {
    padding: var(--padding-base) 0;

    transition: color 0.2s ease 0s; 

    &:hover, &[aria-current] {
        color: var(--color-orange); 
    }
 }

// mm secondary formats
.drawer-column-title {
    font-family: var(--font-family-sans);
    font-size: 0.875rem;
    font-weight: var(--weight-medium);
    color: var(--color-orange); 
    letter-spacing: 0.125rem;
    text-transform: uppercase;
}

.drawer-column-div {
    // &[data-variant*='divider'] {}
    &[data-variant='divider'] {
        position: relative;
        &:before {
            @include divider-line(var(--color-white));
            margin: var(--padding-base) 0;
        }
    }
}


            
