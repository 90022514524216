// Scrollbar 
body {
    scrollbar-color: var(--color-light) var(--color-orange);
}
::-webkit-scrollbar {
    height: .7rem;
}
::-webkit-scrollbar-thumb {
    background-color: var(--color-orange);
}
::-webkit-scrollbar-track {
    background-color: var(--color-light);
}
