// base layout for menus
// from kirby
.menu {
    display: flex;
}
.menu a {
    padding: var(--padding-base); // 1rem
    display: block;
}
.menu a[aria-current] {
    // text-decoration: underline;
}
