.skip-link {
    position: absolute;
    top: clamp(1.09rem,1rem + .47vw,1.33rem);
    left: clamp(1.09rem,1rem + .47vw,1.33rem);
}

.skip-link:not(:focus), .visually-hidden, :not(:hover)>.heading-permalink {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
