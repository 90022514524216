.img-wrapper {

  @at-root .img-caption {
    @include caption(); // mixin
  }
  
  // if default is set - override options
  &[data-variant*='default'] {

  }

  // mimic card layout (should be set by Panel User)
  &[data-variant*='card-figure'] {
    @include card-wrapper();

    .image {
      @include borders-s; // mixin
     
      position: relative;
      overflow: hidden;
      margin-bottom: calc(var(--grid-gutter) * 1.5); // 1.5rem
    }

    /*
    .img-caption {

    }
    */
  }
}