  img {
    width: 100%;
  }


  li {
    list-style: none;
  }
 
  a {
    color: currentColor;
    text-decoration: none;
  }
  
  button {
    font: inherit;
    background: none;
    border: 0;
    color: currentColor;
    cursor: pointer;
  }
  strong, b {
    font-weight: 600;
  }
  small {
    font-size: inherit;
    color: var(--color-text-grey);
  }
  
  .bg-light {
    background-color: var(--color-light);
  }
  .color-grey {
    color: var(--color-text-grey);
  }

  
  hr {
    border: 0;
    background: currentColor;
    height: 2px;
    width: 1.5rem;
    margin: 3rem auto;
  }
  
  .align-center {
    text-align: center;
  }
  

  
  .cta {
    background: var(--color-black);
    color: var(--color-white);
    display: inline-flex;
    justify-content: center;
    padding: .75rem 1.5rem;
    border: 4px solid var(--color-white);
    outline: 2px solid var(--color-black);
  }
  
  .box {
    background: var(--color-light);
    padding: 1.5rem;
    border: 4px solid var(--color-white);
    outline: 2px solid var(--color-light);
  }

  .video,
  .img {
    position: relative;
    display: block;
    --w: 1;
    --h: 1;
    padding-bottom: calc(100% / var(--w) * var(--h));
    background: var(--color-black);
  }
  .img img,
  .video iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
  }
  .img[data-contain] img {
    object-fit: contain;
  }
  .img-caption,
  .video-caption {
    padding-top: .75rem;
    line-height: 1.5em;
  }
  
  
  
  .map {
    --w: 2;
    --h: 1;
    padding-bottom: calc(100% / var(--w) * var(--h));
    position: relative;
    overflow: hidden;
    background: var(--color-black);
  }
  .map iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .margin-s {
    margin-bottom: .75rem;
  }
  .margin-m {
    margin-bottom: 1.5rem;
  }
  .margin-l {
    margin-bottom: 3rem;
  }
  .margin-xl {
    margin-bottom: 4.5rem;
  }
  .margin-xxl {
    margin-bottom: 6rem;
  }
  
  
  @media screen and (min-width: 60rem) {
    body {
      --padding: 3rem;
    }
  
  }
  
  .pagination {
    display: flex;
    padding-top: 6rem;
  }
  .pagination > span {
    color: var(--color-text-grey);
  }
  .pagination > * {
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border: 2px solid currentColor;
    margin-right: 1.5rem;
  }
  .pagination > a:hover {
    background: var(--color-black);
    color: var(--color-white);
    border-color: var(--color-black);
  }
  
  .note-excerpt {
    line-height: 1.5em;
  }
  .note-excerpt header {
    margin-bottom: 1.5rem;
  }
  .note-excerpt figure {
    margin-bottom: .5rem;
  }
  .note-excerpt-title {
    font-weight: 600;
  }
  .note-excerpt-date {
    color: var(--color-text-grey);
  }
  
  
  