/*
 * nav 
 * <nav> wrapper
 */
 .mainnav {
    @include font-size-regular-layout();
    
    color: var(--color-white); 

    // desktop navigation (main element)
    &[data-variant='desktop'] {
        display: none; 
        @include media-query('lg') {
            display: block; 
        }
        .container {
            // padding: 0 var(--padding-base);
        }
    }

    // mobile navigation (main element)
    &[data-variant='mobile'] {
        
    }
   
}


/*
 * main menu 
 * <ul mainmenu>
 */
.mainmenu {

    // li
    // @at-root .mainmenu-item {}
}

