.full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

/*
.main-bleed {
    width: calc(100% + (2*var(--padding-base)));
    margin-left: calc(-1 * var(--padding-base)); 
    margin-right: calc(-1 * var(--padding-base));
    
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
}
*/