// Google Fonts Inter (Sans) & Roboto Slab (Slab)
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700;900&family=Roboto+Slab:wght@200;400;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Lora:400,500,600,700&display=swap|Poppins:400,700,900&display=swap|Zilla+Slab:400,600&display=swap'); */


// Scaffolding Variables (use in font-familiy or gorko processing for css-vars)
$sans: '"Inter", Helvetica, Arial, sans-serif';
$slab: '"Roboto Slab", "Zilla Slab", serif';
$serif: $slab;
$mono: '"SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace';
