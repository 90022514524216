.excerpt {
    figure, .img {
        margin-bottom: var(--margin-m);
    }

    @at-root .excerpt-title {
        margin-bottom: var(--margin-xs);
        transition: color 150ms;
    }

    // link wrapper 
    > a {
        display: block; 
        position: relative;
        transition: transform 0.3s ease-out;

        &:hover {
            transform: translateY(-4px);

            .excerpt-title {
                color: var(--color-orange); 
            }
        }
    }
}