.links {

    @at-root #{&}-s-header {
        display: flex;
        flex-basis: auto;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .link:not(:last-child) {
            margin-right: 0.5rem; 
        } 
    }
    
    @at-root #{&}-prevnext {
        display: flex;
        flex-direction: column;

        &[data-variant*='flip-order'] {
            // older article
            .link-wrap:nth-of-type(1) {
                // order: 2;
                .link {
                    flex-direction: row-reverse; 

                    &:hover {
                        .link-icon-wrap .icon {
                            transform: translateX(-4px);
                        }
                    }
                }
                
            }
            // newer article
            /*.link-wrap:nth-of-type(2) {
                // order: 1; 
            }*/
        }
    }

    @at-root #{&}-pagination {
        display: flex;
        flex-basis: auto;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .link-icon:nth-of-type(1) {
            padding: 0.2rem 0.4rem 0.2rem 0rem;
        }
        .link-icon:nth-of-type(2) {
            padding: 0.2rem 0rem 0.2rem 0.4rem;
        }
    }

    @at-root #{&}-pagination-range {
        display: flex;
        flex-basis: auto;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        
        > li > a {
            padding: 0.2rem 0.4rem; 
        }
    }

    @at-root #{&}.categories.categories-list {
        > li a {
            // line-height: 1;
            padding: 0.4rem 0;
        }
        .link-icon-wrap {
            height: 1rem; 
        }
    }
}

/*
.link {
}
*/

.link-label {
    @include meta-type();
}

// link w/icon - eg used in quicknav or section header links (eg arrowed link)
.link-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // specifiv variants
    /* &[data-variant*='link-arrow'] {
        // eg anim styles here
    } */ 

    &:hover {
        .link-icon-wrap .icon {
            transform: translateX(4px);
        }
    }
}
.link-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 20px; 
    padding: 0 0.4rem; 
    
    .icon {
        height: 100%;
        width: auto;
        transition: transform 0.3s ease-out;
    }

    // specific icon overrides
    /* &[data-variant*='arrow-right'] {
       
    } */
}