.media {
    // @include card-wrapper();
    position: relative; 
    
    
    // &[data-variant*='video'] {}

    // type
    &.media-single {
        @include borders-m;
        overflow: hidden; 
    }
    &.media-gallery {
        list-style-type: none;
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: column;
        @include media-query('md') { 
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
        }

        li {
            list-style-type: inherit; 
            padding: calc(var(--grid-gutter) / 2); 
            
        }
        .media-link {
            position: relative;
            border-radius: 0.5rem;
            overflow: hidden;
            transition: transform 0.18s ease; 
            &:hover {
                transform: translateY(-3px);
            }
        }
    }

    // variant
    &[data-variant*='overlay'] {
        color: var(--color-white); 
        transition: color .77s ease;

        .madia-image img {
            transition: transform .77s ease;
        }

        &:hover, &:focus {
            color: var(--color-orange); 
            .madia-image img {
                transform: scale(1.1);
            }
        }
    }
}

.madia-image {
    position: relative;
    overflow: hidden; 
    height: 420px; 
    @include media-query('lg') { 
        height: auto; 
    }
}
.madia-image img {
    // transition: transform .77s ease;
}
.media-link {
    position: relative;
    display: block;
}
.media-icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        display: block;
    }
}

.media-overlay {
    position: absolute;
    bottom: 0; 
    left: 0;
    top: auto;
    right: 0;
    width: 100%;
    padding: var(--padding-base); 
    color: inherit; 
    pointer-events: none;

    @include gradient-linear-up-overlay-black(); 

    @include media-query('lg') { 
        padding: var(--padding-base) calc(var(--padding-base) * 2); 
    }
}

.media-overlay .text {
    p {
        max-width:60ch; 
    } 
}