// section global
.section {
    padding-top: var(--margin-l);
    padding-bottom: var(--margin-l);
    margin-bottom: var(--margin-xxl);

    &[data-variant*='has-bg'] {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;  
    }    
}

// section header
.s-header {
    margin-bottom: var(--margin-m);
    @include media-query('lg') {
        margin-bottom: var(--margin-l);
    }
}
.s-header .container {
    display: flex;
    flex-basis: auto;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
/*
.s-header-link {
    display: block;
}
*/
