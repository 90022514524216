.header {
    @include font-size-regular();
    
    position: relative; 
    z-index: z('header');

    padding: 0 calc(2 * var(--padding-base));
    @include media-query('sm-max') {
        padding: 0 var(--padding-base);
    }
}
.header > * {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-right: calc(var(--padding-base) * -1);
    margin-left: calc(var(--padding-base) * -1);
    
    margin-bottom: var(--margin-m);
    @include media-query('lg') {
        margin-bottom: var(--margin-l);
    }
}