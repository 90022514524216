.logo {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    // font-weight: 600;
    cursor: pointer;

    color: var(--color-white);

    width: 20rem; // size control ~320pc
    z-index: z(header);

    @include media-query('sm-max') {
      width: 12rem;
    }
  }

.logo svg {
  width: 100%; 
  height: auto; 
}

.logo svg#logo {
  // swap fill colors hover
  #border {
    fill: var(--color-orange);
  }
  #type {
    fill: var(--color-white);
  }
  #border, #type {
    transition: fill .4s ease;
  }
  &:hover {
    /*
    #border {
      fill: var(--color-white);
    }
    */
    #type {
      fill: var(--color-orange);
    }
  }
}