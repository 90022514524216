.main {
    width: calc(100% - (2 * var(--padding-base))); 
    max-width: var(--max-width-main); /* 1888px */ 
    margin-left: auto;
    margin-right: auto;
    padding-bottom: var(--margin-xl); 
    // padding: 0 var(--padding-base);
    background: var(--color-white);

    @include media-query('sm-max') {
        width: 100%;
    }
    @include media-query('sm') {
        border-radius: .825rem; 
        // overflow: hidden; // causes sticky elements children to not work !!!
    }

    // last elem TEMPORARY
    // needs overflow -> no GOOD (sticky elements inside!)
    // --> USE PADDING BOTTOM on MAIN
    /*
    >*:last-child {
        margin-bottom: var(--margin-xl); 
    }
    */
}

// padding all direct child elements 
// but not on a container element! ( see blog and news templates etc)
// .main > *:not(.container) {
.main > * {
    padding: 0 var(--padding-base);
}