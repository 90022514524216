// https://codepen.io/alphardex/pen/NWxNyXb

$off-canvas-bg-color: #1a1e23; // rgb 26, 30, 35 for #1a1e23
$off-canvas-header-color: rgba(26,30,35,0.83);
.off-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: flex-start;
    background: rgba($off-canvas-bg-color,1);
    opacity: 0;
    overflow-x: hidden;
    pointer-events: none;
    // visibility: hidden;
    transition: 0.3s;
    z-index: z('offcanvas');
    &[data-variant='mainnav'] {
        @include media-query('lg') {
            display: none; 
        }
    }
}

.off-canvas-inner {
    position: relative;
    width: 100%;
    padding: 120px 5vw 60px; // make room for header (logo & xOut) an side spacing
}

.off-canvas-inner > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 520px;
    
    &:not(.mainnav) {
        @include font-size-regular-layout(); // mainnav should be the same (or mayber larger)
    }
    &:not(:last-child) {
        margin-bottom: calc(var(--margin-m) * 1.5);
    }

}


// active state (JS)
.off-canvas.is-active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

// html element & body (JS)
.off-canvas-active {
    body {
        overflow: hidden; 
    }
    .header {
        background: $off-canvas-header-color;
    }
}