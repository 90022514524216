.topbar {
    display: none; 
    position: relative;
    z-index: 7;
    @include font-size-smaller(); 
    
    background-color: var(--color-blue-dark);
    color: var(--color-white);
    
    padding: 0 calc(2 * var(--padding-base));
    @include media-query('sm-max') {
        padding: 0 var(--padding-base);
    }
    
    @include media-query('lg') {
        display: block; 
    }
}

// grid
.tb-columns {
    display: flex;
    flex-flow: row nowrap; // shorhand flex-direction & flex-wrap
    align-items: center;
    justify-content: space-between; 
    // align-content: flex-start; // align content is multiline only
}
/*
.tb-column {

}
*/

/*
 * Topbar Nav-Icons
*/
 .nav-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-icon-set {
    position: relative;
    // overflow: hidden;
    // transition: all 0.2s ease-in-out;
    // 
    // margin: 30px;
    // background: rgb(248, 248, 248);
    // border: solid 1px rgb(222, 222, 222);
    // height: 21px;
    // height: 0px; 
    cursor: pointer;
}
.nav-icon {
    height: 32px;
    padding: 8px; 
    width: auto;
    margin-left: 0.5rem; 
    margin-right: 0.5rem; 
    .icon {
        height: 100%; 
        width: auto; 
    }

    &[data-variant='has-text'] {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        
        .icon {
            margin-right: 0.25rem; 
        }
    }
}
.nav-icon-dropdown {
    position: absolute; 
    overflow: hidden;
    top: auto; 
    right: 0.5rem; 
    width: 228px; 
    height: 0px; 
    background-color: var(--color-blue-dark);
    color: var(--color-light); 
    text-align: center;
    border-bottom-right-radius: 0.5rem;
    z-index: 6;
}
.nav-icon-dropdown > li {
    
    transition: background-color 0.2s ease-in-out; 

    &:not(:first-child) {
        // border-top: solid 1px rgb(202, 202, 202);
        border-top: solid 1px var(--color-blue); 
    }
    
    &:hover {
        background-color: var(--color-orange); 
    }
}
.nav-icon-dropdown > li > a {
    display: block; 
    @include font-size-smaller();
    font-weight: 500;
    padding: 14px 10px;
}

// open state
.nav-icon-set.is-open {
    .nav-icon-dropdown {
        height: auto;
    } 
}



