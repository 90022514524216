.block-type-video {
    figure {
        margin-bottom: var(--flow-space, 3rem);
    }

    // make figcaption avalaible for all layout editors
    @at-root figcaption {
        @include caption(); // mixin
    }
   
}

